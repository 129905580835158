@charset "utf-8";

// Customize the Foundation-5 label-class:
$label-font-sizing: 0.9rem;
$label-font-weight: bold;

// Import partials from `sass_dir` (defaults to `_sass`)
// Add foundation SCSS (A.Chamberlin)
@import
        "normalize",
        "foundation/settings",
        "foundation"
;
